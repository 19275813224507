<template>
	<div>
<!---订单列表-->
		<div class="ygdtitle">
			投稿中订单
		</div>
		<div v-if="orderlistlength==0" class="nodata">
						暂无数据
		</div>
		<div v-else>
		<order :orderlist="orderlist"></order>
		<div class="ygdPage">
			<el-pagination class="fy" small @size-change="handleSizeChange" :page-size="pagesize" :page-sizes="[10,20,30]" layout="total, sizes, prev, pager, next, jumper" @current-change="current_change" :total="total" background>
			</el-pagination>
		</div>
		</div>
	</div>
</template>

<script>
	import order from '../components/order' 
	import {orderlist} from '@/API/YGD/orderlist';
	export default {
		components: {
  		 order,
  		
 	 },
		data() {
			return {
				now:1,
				childpath:"/Staff/contribution/detail",
				total:0, //默认数据总数
				pagesize:10, //每页的数据条数
				currentPage: 1, //默认开始页面
				orderlist:[//订单列表数据
				],
				orderlistlength:0,//订单列表长度
				
			}
		},
		methods:{
			// 分页
			current_change: function(currentPage) {
				this.currentPage = currentPage;
				this.todoOrder();
			},handleSizeChange(val) {
        		console.log(val);
        		this.pagesize=val;
        		this.todoOrder();
     	},
		//调取投稿中订单接口
				todoOrder(){
				orderlist( localStorage.getItem('UserId'),this.currentPage,this.pagesize,1).then(res=>{
					console.log(res);
					if(res.data.code==0){
						this.orderlist=res.data.data
						this.orderlistlength=this.orderlist.length;
						this.total=res.data.total
					}
				})
			}
		},
		prop:[""],
		  mounted () {
		  	this.todoOrder();
		  }
		
	}
</script>

<style>
	.ygdtitle {
	
	
	  height: 60px;
	  line-height: 60px;
	  border-bottom: 1px solid #e6e6e6;
	  margin-bottom: 20px;
	
	}
	.num {
	  display: flex;
	  justify-content: space-between;
	  margin-bottom: 40px;
	  font-size: 14px;
	  font-family: PingFangSC-Regular, PingFang SC;
	  font-weight: 400;
	  color: #333333;
	}
	.num span {
	  margin-right: 30px;
	  cursor: pointer;
	}
	.num span:hover {
	  color: #ff1918;
	
	}
</style>